var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"publication",attrs:{"tag":"div","id":"scroll-block"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"publication__header"},[_c('h3',{staticClass:"publication__header_title"},[_vm._v(_vm._s(_vm.publication.id ? "Edit" : "Add")+" Publication")]),_c('div',{staticClass:"publication__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete Item"},on:{"click":function($event){return _vm.$emit('removePublication', _vm.publication)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid || !_vm.changed},on:{"click":_vm.savePublication}})],1)]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Title","required":true,"validateError":errors[0],"element":_vm.publication.title},on:{"input":function($event){_vm.publication.title = $event}},model:{value:(_vm.publication.title),callback:function ($$v) {_vm.$set(_vm.publication, "title", $$v)},expression:"publication.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Type","required":true,"validateError":errors[0],"element":_vm.publication.type},on:{"input":function($event){_vm.publication.type = $event}},model:{value:(_vm.publication.type),callback:function ($$v) {_vm.$set(_vm.publication, "type", $$v)},expression:"publication.type"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Author","required":true,"validateError":errors[0],"element":_vm.publication.author},on:{"input":function($event){_vm.publication.author = $event}},model:{value:(_vm.publication.author),callback:function ($$v) {_vm.$set(_vm.publication, "author", $$v)},expression:"publication.author"}})]}}],null,true)})],1),_c('ValidationProvider',{staticClass:"form__row",attrs:{"tag":"div","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('MultipleAutocompleteInput',{class:{ 'autocomplete-input--invalid': errors[0] },attrs:{"id":"autocomplete-category","creating":false,"focusOpen":true,"required":true,"validateError":errors[0],"loading":_vm.artistsLoading,"items":_vm.publication.artists,"matches":_vm.artists,"placeholder":"Artists"},on:{"input":function($event){_vm.publication.artists = $event},"searchMatches":_vm.apiGetSearchedArtists},model:{value:(_vm.publication.artists),callback:function ($$v) {_vm.$set(_vm.publication, "artists", $$v)},expression:"publication.artists"}})]}}],null,true)}),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ url: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Url","validateError":errors[0] || _vm.serverError.slug,"element":_vm.publication.slug},on:{"input":function($event){_vm.publication.slug = $event}},scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('span',{staticClass:"form-control__simple-text"},[_vm._v(" If available for sale on an external website, paste the link here. ")]),_c('span',{staticClass:"form-control__simple-text"},[_vm._v(" URL: "),_c('a',{staticClass:"form-control__simple-text",attrs:{"href":_vm.publication.slug,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.publication.slug)+" ")])])]},proxy:true}],null,true),model:{value:(_vm.publication.slug),callback:function ($$v) {_vm.$set(_vm.publication, "slug", $$v)},expression:"publication.slug"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }